body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #001224;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 68, 68);
}

/* remove arrows from number input  */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.verticalCenter {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: "100%";
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: "100%";
}

.pointer {
  cursor: pointer;
}

.dark-1 {
  background-color: #0f051b;
  color: #bfbfbf;
}
.dark-2 {
  background-color: rgba(179, 179, 179, 0.08);
  color: #bfbfbf;
}
.dark-3 {
  background-color: rgba(179, 179, 179, 0.12);
  color: #d4d1d1;
}

div {
  font-size: 0.8rem;
  color: #bfbfbf;
}
a {
  color: white !important;
  /* text-decoration: none; */
}
a:hover {
  color: white !important;
  /* text-decoration: none !important; */
}

span,
button {
  color: #bfbfbf;
}

.sidebarItem {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin: 0 0 0 0;
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: left;
  display: block;
  text-decoration: none;
  min-width: 100%;
  overflow: hidden;
  max-height: 2.2rem;
}
.sidebarItem:hover {
  background-color: #0f051b;
  text-decoration: none;
}
.sidebarItem-active {
  background-color: #0f051b;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  margin: 0 0 0 0;
  cursor: pointer;
  border: none;
  text-align: left;
  display: block;
  text-decoration: none;
  min-width: 100%;
  overflow: hidden;
  max-height: 2.2rem;
}
.sidebarItem-active:hover {
  text-decoration: none;
}

.gray-btn-active {
  background-color: #4d4d4d;
  color: "#b3b3b3";
  border: 1px solid #4d4d4d;
  text-decoration: none;
}
.gray-btn-active:hover {
  background-color: #5e5d5d;
  border-color: black;
  color: "white";
  text-decoration: none;
}
.gray-btn {
  background: none;
  color: "#b3b3b3";
  border: 1px solid #4d4d4d;
  text-decoration: none;
}
.gray-btn:hover {
  background-color: #4d4d4d;
  color: "white";
  border: 1px solid #4d4d4d;
  text-decoration: none;
}

.form-control {
  background-color: black !important;
  color: rgb(158, 158, 158) !important;
  border: 1px solid gray !important;
}
.form-control:focus {
  border-color: black !important;
}
.form-control:disabled {
  color: gray !important;
}

/* Tooltip */
.my-tooltip {
  position: relative;
  /* display: inline-block; */
}

/* Tooltip text */
.my-tooltip .tooltiptext {
  visibility: hidden;
  width: 11rem;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 5px;
  border-radius: 6px;
  top: -5px;
  right: 105%;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100000;
}

/* Show the tooltip text when you mouse over the tooltip container */
.my-tooltip:hover .tooltiptext {
  visibility: visible;
}
.my-tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

/* Tooltip text for create bot page */
.tooltipMy .tooltiptextmy {
  visibility: hidden;
  width: 25rem;
  background-color: #424242;
  color: #fff;
  /* text-align: "left"; */
  padding: 5px 0;
  border-radius: 6px;
  top: -10px;
  left: 50%;
  padding: 10px;
  /* word-wrap: break-word; */
  text-align: justify;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  font-weight: normal;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipMy:hover .tooltiptextmy {
  visibility: visible;
}

/* toggole */
.content {
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
  /* width: 70%; */
  transform: translateY(5%);
}

.toggle-label,
.toggle {
  height: 1.5rem;
  border-radius: 50px;
}
.toggle-label {
  width: 100%;
  background-color: #001224;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
}
.toggle {
  position: absolute;
  width: 50%;
  background-color: #02488f;
  box-shadow: 0 2px 15px rgb(0, 0, 0, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0.7;
}

.names {
  font-weight: bolder;
  color: #ffffff;
  width: 100%;
  /* text-align: center; */
  margin-top: 0.18rem;
  position: absolute;
  display: flex;
  justify-content: space-around;
  /* user-select: none; */
}
.dark {
  opacity: 0.5;
}

/* -------- Switch Styles ------------*/
#customNotificationToggle {
  display: none;
}
/* Toggle */
#customNotificationToggle:checked + .app .toggle {
  transform: translateX(100%);
  background-color: #02488f;
  color: #ffffff;
  opacity: 1;
}
#customNotificationToggle:checked + .app .dark {
  opacity: 1;
}
#customNotificationToggle:checked + .app .light {
  opacity: 0.5;
}

.bot-details-row {
  background-color: rgba(102, 179, 255, 0);
}
.bot-details-row:hover {
  background-color: rgba(102, 179, 255, 0.02);
}

.ordersDetailsButton {
  background-color: #353535;
  border-radius: 3px;
  border: none;
  margin: 1px;
  padding: 0.2rem;
}

.ordersDetailsButton:hover {
  background-color: #242424;
}

.ordersDetailsButton:focus {
  background-color: #242424;
  font-weight: bold;
}
